import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
export default function SeccionRequeridoCliente({ expediente }) {
    const divLeer = { height: "35px", width: "100%", backgroundColor: "white" };
    const bot3 = {
        background: "#F99F41 0% 0% no-repeat padding-box",
        borderRadius: "22px",
        height: "41px",
        width: "180px",
    };
    const aL = { textDecoration: "none", color: "white" };
    const [cookies] = useCookies(["user"]);
    const hora = localStorage.getItem("hora");
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(":").map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(":").map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const editarBtn = {
        background: "#0731FF 0% 0% no-repeat padding-box",
        borderRadius: "22px",
        width: "135px",
        height: "39px",
    };

    const isMobile375 = useMediaQuery({ query: "(max-width: 375px)" });
    const isMobile412 = useMediaQuery({ query: "(min-width: 376px) and (max-width: 412px)", });
    const isMobile480 = useMediaQuery({ query: "(min-width: 413px) and (max-width: 480px)", });
    return (
        <>
            {isMobile375 ? (
                <>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Nombre Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nombreRequerido}
                            </div>
                        </div>
                        <div class="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Nombre del Conductor Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nombreConductorRequerido}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Dominio Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.patenteRequerido}
                            </div>
                        </div>
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Marca/Modelo Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.marcaModeloRequerido}
                            </div>
                        </div>
                        <div className="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Compañia Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.compania}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                D.N.I Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.dniRequerido}
                            </div>
                        </div>
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Telefono Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.telefonoContacto}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                N. Siniestro Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nroSeguimientoCompaniaRequerido}
                            </div>
                        </div>
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                N. Reclamo del Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nroReclamoCompaniaRequerido}
                            </div>
                        </div>
                        <div class="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                N. Reclamo Cia Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.polizaRequerido}
                            </div>
                        </div>
                    </div>
                </>
            ) : isMobile412 ? (
                <>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Nombre Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nombreRequerido}
                            </div>
                        </div>
                        <div class="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Nombre del Conductor Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nombreConductorRequerido}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Dominio Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.patenteRequerido}
                            </div>
                        </div>
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Marca/Modelo Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.marcaModeloRequerido}
                            </div>
                        </div>
                        <div className="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Compañia Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.compania}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                D.N.I Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.dniRequerido}
                            </div>
                        </div>
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Telefono Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.telefonoContacto}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                N. Siniestro Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nroSeguimientoCompaniaRequerido}
                            </div>
                        </div>
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                N. Reclamo del Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nroReclamoCompaniaRequerido}
                            </div>
                        </div>
                        <div class="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                N. Reclamo Cia Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.polizaRequerido}
                            </div>
                        </div>
                    </div>
                </>
            ) : isMobile480 ? (
                <>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Nombre Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nombreRequerido}
                            </div>
                        </div>
                        <div class="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Nombre del Conductor Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nombreConductorRequerido}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Dominio Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.patenteRequerido}
                            </div>
                        </div>
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Marca/Modelo Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.marcaModeloRequerido}
                            </div>
                        </div>
                        <div className="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Compañia Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.compania}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                D.N.I Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.dniRequerido}
                            </div>
                        </div>
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                Telefono Requerido
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.telefonoContacto}
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                N. Siniestro Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nroSeguimientoCompaniaRequerido}
                            </div>
                        </div>
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                N. Reclamo del Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.nroReclamoCompaniaRequerido}
                            </div>
                        </div>
                        <div class="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">
                                N. Reclamo Cia Req
                            </label>
                            <div type="text" class="recuasroTexto375">
                                {expediente.polizaRequerido}
                            </div>
                        </div>
                    </div>
                </>
            ) :
                <div>
                    <div className="mt-1 mb-3 pe-3 ps-3">
                        <div class="mb-2 row">
                            <div className="col">
                                <label class="mb-1 letraPlanillaExpediente">
                                    Nombre Requerido
                                </label>
                                <div type="text" class="form-control" style={divLeer}>
                                    {expediente.nombreRequerido}
                                </div>
                            </div>
                            <div class="col">
                                <label class="mb-1 letraPlanillaExpediente">
                                    Nombre del Conductor Requerido
                                </label>
                                <div type="text" class="form-control" style={divLeer}>
                                    {expediente.nombreConductorRequerido}
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div className="col">
                                <label class="mb-1 letraPlanillaExpediente">
                                    Dominio Requerido
                                </label>
                                <div type="text" class="form-control" style={divLeer}>
                                    {expediente.patenteRequerido}
                                </div>
                            </div>
                            <div class="col">
                                <label class="mb-1 letraPlanillaExpediente">
                                    Marca/Modelo Requerido
                                </label>
                                <div type="text" class="form-control" style={divLeer}>
                                    {expediente.marcaModeloRequerido}
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div className="col">
                                <label class="mb-1 letraPlanillaExpediente">
                                    Compañia Requerido
                                </label>
                                <div type="text" class="form-control" style={divLeer}>
                                    {expediente.compania}
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div className="col">
                                <label class="mb-1 letraPlanillaExpediente">
                                    D.N.I Requerido
                                </label>
                                <div type="text" class="form-control" style={divLeer}>
                                    {expediente.dniRequerido}
                                </div>
                            </div>
                            <div class="col">
                                <label class="mb-1 letraPlanillaExpediente">
                                    Telefono Requerido
                                </label>
                                <div type="text" class="form-control" style={divLeer}>
                                    {expediente.telefonoContacto}
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 row">
                            <div class="col">
                                <label class="mb-1 letraPlanillaExpediente">
                                    Nro de Siniestro Requerido
                                </label>
                                <div type="text" class="form-control" style={divLeer}>
                                    {expediente.nroSeguimientoCompaniaRequerido}
                                </div>
                            </div>
                            <div className="col">
                                <label class="mb-1 letraPlanillaExpediente">
                                    Nro de Reclamo del Requerido
                                </label>
                                <div type="text" class="form-control" style={divLeer}>
                                    {expediente.nroReclamoCompaniaRequerido}
                                </div>
                            </div>
                            <div class="col">
                                <label class="mb-1 letraPlanillaExpediente">
                                    Nro de Reclamo Cia Requerido
                                </label>
                                <div type="text" class="form-control" style={divLeer}>
                                    {expediente.polizaRequerido}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
