import './login.css'
import banner from '../images/bannerLoginCliente.jpeg'
import logo from '../images/logoTuveUnChoque.png'
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
export default function LoginCliente() {
    const navigate = useNavigate()
    const [datos, setDatos] = useState({patente: '', fechaStro: ''})
    function convertirFecha(fecha) {
        const partes = fecha.split('-');
        return `${partes[2]}/${partes[1]}/${partes[0]}`;
      }
      async function obtenerExpediente (e) {
        e.preventDefault()
        try {
            const response = await axios.get(`https://api.tuveunchoque.com.ar/api/cliente/expedientesCliente?patente=${datos.patente}&fechaStro=${convertirFecha(datos.fechaStro)}`);
            localStorage.setItem('expCliente',  JSON.stringify(response.data));
            navigate('/cliente/exp')
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.log("Expediente no encontrado:", error.response.data.message);
            } else {
                console.error("Error al buscar el expediente:", error.message);
            }
        }
    }
    return (
        <>
            <main className="mainLoginCliente">
                <section className="fotoMainClienteLadoIzqContenedor"><img src={banner} alt="" className='fotoMainClienteLadoIzq'/></section>
                <section className='m-auto mt-0'>
                    <img src={logo} alt="" width={'400px'} height={'100px'} style={{ marginTop: '80px' }} />
                    <form action="" className="center" onSubmit={obtenerExpediente}>
                        <div className='text-center'>
                            <label htmlFor="" className="labelLogin text-center">PATENTE</label>
                            <input type="text" className="inputPatenteLogin" 
                            onChange={(e) =>
                                setDatos((prevState) => ({
                                    ...prevState,
                                    patente: e.target.value,
                                }))
                            }/>
                            <label className='labelFechaStroLogin text-center'>FECHA DE SINIESTRO</label>
                            <div class="inputDateContainerLogin">
                                <i class="bi bi-calendar2-week-fill calendar-icon"></i>
                                <input type="date" class="inputDateLogin" 
                                onChange={(e) =>
                                    setDatos((prevState) => ({
                                        ...prevState,
                                        fechaStro: e.target.value,
                                    }))
                                }/>
                            </div>
                            <button type="submit" className='submitLoginNuevo'>INGRESAR</button>
                        </div>
                    </form>
                </section>
            </main>
        </>
    );
}
