import axios from "axios";
import { useCallback, useState, useEffect } from "react";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
export default function AgregarExpediente() {
    const navigate = useNavigate();
    const a = localStorage.getItem('user');
    if (a === null) {
        navigate('/login')
    }
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [expedientes, setExpedientes] = useState([]);
    const [companias, setCompanias] = useState([])
    const [productores, setProductores] = useState([])
    const [carga, setCarga] = useState(false)
    useEffect(() => {
            axios.get("https://api.tuveunchoque.com.ar/api/crearExp/allExp").then((res) => {
                setExpedientes(res.data.reverse());
            });
            axios.get('https://api.tuveunchoque.com.ar/api/gerencia/companias').then((res) => {
                setCompanias(res.data);
            });
            axios.get('https://api.tuveunchoque.com.ar/api/gerencia/pas').then((res) => {
                setProductores(res.data);
            });
        
    }, []);
    const [expedienteData, setExpedienteData] = useState({
        fechaStro: "", estado: "RECEPCIONADO", monto: "", asignado: cookies.user.cargo === 'AGENTE' ? cookies.user.nombre : '', nivel: "", infoAdicional: '', nombre: '', nombreConductor: '', dominio: '', pas: '', honorarios: '', marca: '', tel: '', dni: '', mail: '', nombreRequerido: '', nombreConductorReq: '', dominioReq: '', marcaReq: '', compReq: '', nroSeg: '', nroReclamoReq: '', nroReclamoCia: '', telReq: '', dniReq: '', vencimiento: ''
    });
    const regEx = /(^[a-zA-Z]{3}[-]{0,1}[0-9]{3,4}$)|(^[a-zA-Z]{2}[-]{0,1}[0-9]{3}[a-zA-Z]{1,2}$)|(^[0-9]{3}[-]{0,1}[a-zA-Z]{3,4}$)|(^[a-zA-Z]{1}[-]{0,1}[0-9]{3}[a-zA-Z]{3}$)/
    const divInfo = { height: "105px", width: "100%" };
    const hoy = new Date().toISOString().split('T')[0];
    const [doc, setDoc] = useState({ cedula: [], registro: [], presupuesto: [], certificado: [], dni: [], automotor: [], fotos: [], lesionesDoc: [], lesionesFotos: [] })
    const mailData = new FormData();
    for (var i = 0; i < doc.dni.length; i++) {
        mailData.append(`dni${i}`, doc.dni[i]);
    }
    for (var i = 0; i < doc.cedula.length; i++) {
        mailData.append(`cedula${i}`, doc.cedula[i]);
    }
    for (var i = 0; i < doc.registro.length; i++) {
        mailData.append(`registro${i}`, doc.registro[i]);
    }
    for (var i = 0; i < doc.certificado.length; i++) {
        mailData.append(`ocho${i}`, doc.certificado[i]);
    }
    for (var i = 0; i < doc.presupuesto.length; i++) {
        mailData.append(`presupuesto${i}`, doc.presupuesto[i]);
    }
    for (var i = 0; i < doc.automotor.length; i++) {
        mailData.append(`automotor${i}`, doc.automotor[i]);
    }
    for (var i = 0; i < doc.fotos.length; i++) {
        mailData.append(`fotos${i}`, doc.fotos[i]);
    }
    for (var i = 0; i < doc.lesionesDoc.length; i++) {
        mailData.append(`lesionesDoc${i}`, doc.lesionesDoc[i]);
    }
    for (var i = 0; i < doc.lesionesFotos.length; i++) {
        mailData.append(`lesionesFotos${i}`, doc.lesionesFotos[i]);
    }
    const expedienteDataCreate = useCallback(async (e) => {
        e.preventDefault()
        setCarga(true)
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/nuevo`, {
            fechaExp: hoy,
            vencimiento: new Date(expedienteData.vencimiento).toLocaleDateString('fr-FR'),
            fechaStro: expedienteData.fechaStro,
            estado: expedienteData.estado,
            monto: expedienteData.monto,
            asignado: expedienteData.asignado,
            nivel: expedienteData.nivel,
            infoAdicional: expedienteData.infoAdicional,
            nombre: expedienteData.nombre,
            nombreConductor: expedienteData.nombreConductor,
            dominio: expedienteData.dominio,
            pas: expedienteData.pas,
            honorarios: expedienteData.honorarios,
            marca: expedienteData.marca,
            tel: expedienteData.tel,
            dni: expedienteData.dni,
            mail: expedienteData.mail,
            nombreRequerido: expedienteData.nombreRequerido,
            nombreConductorReq: expedienteData.nombreConductorReq,
            dominioReq: expedienteData.dominioReq,
            marcaReq: expedienteData.marcaReq,
            compReq: expedienteData.compReq,
            nroSeg: expedienteData.nroSeg,
            nroReclamoReq: expedienteData.nroReclamoReq,
            nroReclamoCia: expedienteData.nroReclamoCia,
            telReq: expedienteData.telReq,
            dniReq: expedienteData.dniReq,
            modificadoPor: cookies.user.nombre
        }).then(async (response) => {
            if (response.status === 200) {
                await axios.post(`https://api.tuveunchoque.com.ar/api/mail/allNuevoExp?nro=${response.data.nro}`, mailData, { headers: { "Content-Type": "multipart/form-data" } })
                await axios.post(`https://api.tuveunchoque.com.ar/api/mail/recepcionado`, {
                    nro: response.data.nro,
                    pas: response.data.productorAsociado,
                    honorariosPas: response.data.honorarios,
                    patente: response.data.dominioCliente
                })
                setTimeout(() => {
                    Swal.fire(
                        'Expediente creado!',
                        `<p>Nro: ${response.data.nro}<br/>Nombre: ${response.data.nombre}<br/>Patente: ${response.data.dominioCliente}<br/>Compañia: ${response.data.compania}<br/>Productor: ${response.data.productorAsociado}<br/>Honorarios: ${response.data.honorarios}<br/>Estado: ${response.data.estado}</p>`,
                        'success'
                    ).then(async () => {
                        window.location.reload()
                    })
                }, 500);
            }
        })
    }, [expedienteData]);
    const e = expedientes.find(e => e.dominioCliente === expedienteData.dominio)
    const m = expedientes.find(e => e.dominioCliente === expedienteData.dominio && e.fechaStro === expedienteData.fechaStro)
    const m2 = expedientes.find(e => e.dominioCliente === expedienteData.dominioReq && e.fechaStro === expedienteData.fechaStro && e.patenteRequerido === expedienteData.dominio)
    function dom() {
        if (m2 !== undefined) {
            return <h6 className="text-danger center">Detectamos que la patente del cliente y fecha de stro coinciden con los datos de requerido de otro expediente. Favor de revisar.</h6>
        } else if (m !== undefined) {
            return <h6 className="text-danger center">Existe otro expediente con la misma patente y la misma fecha de Stro.</h6>
        } else if (e !== undefined) {
            return <h6 className="text-danger center">Existen otros expedientes con la misma patente.</h6>
        }
    }
    function dom1() {
        if (hnrsPas && expedienteData.honorarios < 5) {
            return <h6 className="text-danger center">{`El monto minimo para el pas ${expedienteData.pas} perteneciente al grupo ABRA es del 5%`}</h6>
        }
    }
    const p = { color: '#0731FF' }
    const cook = () => {
        if (!cookies.user) {
            navigate('/login')
        } else if (cookies.user.cargo === 'PAS') {
            navigate('/expedientes')
        }
    }
    cook()
    const hnrsPas = expedienteData.pas === 'PRADAL' || expedienteData.pas === 'RAMIREZ' || expedienteData.pas === 'SOKALSKI' || expedienteData.pas === 'FLORES FLAVIA'
    return (
        <main class="mainExpedientes">
            {cookies.user ? <section className="row sectionExpedientes">
                {cookies.user?.cargo !== "PAS" ? <div class="pe-5 ps-5 mt-3">
                    <form action="" onSubmit={expedienteDataCreate}>
                        <div className="row">
                            <div class="col-4 mb-3">
                                <h4 className="mb-4 center mt-4" style={p}>Expediente</h4>
                                <div class="mb-2 row">
                                    <div className="col">
                                        <label class="mb-1">Estado</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={expedienteData.estado}
                                            placeholder={expedienteData.estado}
                                            disabled
                                        />
                                    </div>
                                    <div className="col">
                                        <label class="mb-1">Monto</label>
                                        <input
                                            type="number"
                                            class="form-control"
                                            value={expedienteData.monto}
                                            placeholder=""
                                            onChange={(e) =>
                                                setExpedienteData((prevState) => ({
                                                    ...prevState,
                                                    monto: e.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <div class="col">
                                        <label class="mb-1">Fecha Stro (*)</label>
                                        <input
                                            type="date"
                                            class="form-control"
                                            required
                                            value={expedienteData.fechaStro}
                                            onChange={(e) =>
                                                setExpedienteData((prevState) => ({
                                                    ...prevState,
                                                    fechaStro: e.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="mb-1">Asignado a (*)</label>
                                        <select
                                            value={expedienteData.asignado}
                                            disabled={cookies.user.cargo === 'AGENTE' ? true : false}
                                            required
                                            className="form-select"
                                            onChange={(e) =>
                                                setExpedienteData((prevState) => ({
                                                    ...prevState,
                                                    asignado: e.target.value,
                                                }))
                                            }
                                        >
                                            <option disabled selected value="">
                                                -- Elige una opción --
                                            </option>
                                            <option value="AGOSTINA">Agostina</option>
                                            <option value="CATALINA">Catalina</option>
                                            <option value="DANIELA">Daniela</option>
                                            <option value="FRANCO">Franco</option>
                                            <option value="JUAN">Juan</option>
                                            <option value="SOFIA">Sofia</option>
                                            <option value="MICAELA">Micaela</option>
                                            <option value="FEDERICO">Federico</option>
                                            <option value="FACUNDO">Facundo</option>
                                            <option value="LUCAS">Lucas</option>
                                            <option value="GONZALO">Gonzalo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <div className="col">
                                        <label class="mb-1">Nivel</label>
                                        <select
                                            id="nivel"
                                            name="nivel"
                                            value={expedienteData.nivel}
                                            className="form-select"
                                            onChange={(e) =>
                                                setExpedienteData((prevState) => ({
                                                    ...prevState,
                                                    nivel: e.target.value,
                                                }))
                                            }
                                        >
                                            <option disabled selected value="">
                                                -- Elige una opción --
                                            </option>
                                            <option value="1">Nivel 1</option>
                                            <option value="2">Nivel 2</option>
                                            <option value="3">Nivel 3</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <label class="mb-1">Fecha Vencimiento (*)</label>
                                        <DatePicker locale='es' value={expedienteData.vencimiento} className="form-control" selected={expedienteData.vencimiento} required
                                            onChange={(e) => {
                                                setExpedienteData((prevState) => ({
                                                    ...prevState,
                                                    vencimiento: e,
                                                }))
                                            }} />
                                    </div>
                                </div>
                                <div class="mb-2 row">
                                    <div className="col">
                                        <label class="mb-1">Informacion Adicional</label>
                                        <textarea
                                            className="form-control"
                                            style={divInfo}
                                            value={expedienteData.infoAdicional}
                                            onChange={(e) =>
                                                setExpedienteData((prevState) => ({
                                                    ...prevState,
                                                    infoAdicional: e.target.value,
                                                }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 mb-3">
                                <div>
                                    <h4 className="center mb-4 mt-4" style={p}>Cliente</h4>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1">Nombre Cliente (*)</label>
                                            <input type="text" class="form-control" value={expedienteData.nombre}
                                                required
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        nombre: e.target.value,
                                                    }))} />
                                        </div>
                                        <div class="col">
                                            <label class="mb-1">Nombre Conductor del Cliente</label>
                                            <input type="text" class="form-control" value={expedienteData.nombreConductor}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        nombreConductor: e.target.value,
                                                    }))} />
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1">Dominio Cliente (*)</label>
                                            <input type="text" class="form-control" value={expedienteData.dominio}
                                                required
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        dominio: e.target.value,
                                                    }))} />
                                        </div>
                                        <div class="col">
                                            <label class="mb-1">Marca/Modelo Cliente</label>
                                            <input type="text" class="form-control" value={expedienteData.marca}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        marca: e.target.value,
                                                    }))} />
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1">Honorarios (*)</label>
                                            <input type="text" class="form-control" value={expedienteData.honorarios}
                                                required
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        honorarios: e.target.value,
                                                    }))} />
                                        </div>
                                        <div class="col">
                                            <label class="mb-1">Pas Asociado (*)</label>
                                            <select
                                                required
                                                value={expedienteData.pas}
                                                className="form-select"
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        pas: e.target.value,
                                                    }))
                                                }
                                            >
                                                <option disabled selected value="">
                                                    -- Elige una opción --
                                                </option>
                                                {productores.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((pro) => {
                                                    return <option value={pro['NOMBRE']}>{pro.nombre}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1">Telefono (*)</label>
                                            <input type="tel" class="form-control" value={expedienteData.tel}
                                                required
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        tel: e.target.value,
                                                    }))} />
                                        </div>
                                        <div class="col">
                                            <label class="mb-1">DNI</label>
                                            <input type="text" class="form-control" value={expedienteData.dni}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        dni: e.target.value,
                                                    }))} />
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1">Mail</label>
                                            <input type="mail" class="form-control" value={expedienteData.mail}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        mail: e.target.value,
                                                    }))} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-3 mt-4">
                                <div>
                                    <h4 className="center mb-4" style={p}>Requerido</h4>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1">Nombre Rquerido</label>
                                            <input type="text" class="form-control" value={expedienteData.nombreRequerido}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        nombreRequerido: e.target.value,
                                                    }))} />
                                        </div>
                                        <div class="col">
                                            <label class="mb-1">Nombre del Conductor Requerido</label>
                                            <input type="text" class="form-control" value={expedienteData.nombreConductorReq}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        nombreConductorReq: e.target.value,
                                                    }))} />
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1">Dominio Requerido (*)</label>
                                            <input type="text" class="form-control" value={expedienteData.dominioReq}
                                                required
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        dominioReq: e.target.value,
                                                    }))} />
                                        </div>
                                        <div class="col">
                                            <label class="mb-1">Marca/Modelo Requerido</label>
                                            <input type="text" class="form-control" value={expedienteData.marcaReq}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        marcaReq: e.target.value,
                                                    }))} />
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1">Compañia Requerido (*)</label>
                                            <select
                                                id="compReq"
                                                name="compReq"
                                                value={expedienteData.compReq}
                                                className="form-select"
                                                required
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        compReq: e.target.value,
                                                    }))
                                                }
                                            >
                                                <option disabled selected value="">
                                                    -- Elige una opción --
                                                </option>
                                                {companias.sort((a, b) => a.compania.localeCompare(b.compania)).map((comp) => {
                                                    return <option value={comp['ID']}>{comp.compania}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div class="col">
                                            <label class="mb-1">Nro Seguimiento Requerido</label>
                                            <input type="text" class="form-control" value={expedienteData.nroSeg}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        nroSeg: e.target.value,
                                                    }))} />
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1">Nro de Reclamo del Requerido</label>
                                            <input type="tel" class="form-control" value={expedienteData.nroReclamoReq}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        nroReclamoReq: e.target.value,
                                                    }))} />
                                        </div>
                                        <div class="col">
                                            <label class="mb-1">Nro de Reclamo Cia Requerido</label>
                                            <input type="tel" class="form-control" value={expedienteData.nroReclamoCia}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        nroReclamoCia: e.target.value,
                                                    }))} />
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div class="col">
                                            <label class="mb-1">Telefono Requerido</label>
                                            <input type="tel" class="form-control" value={expedienteData.telReq}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        telReq: e.target.value,
                                                    }))} />
                                        </div>
                                        <div className="col">
                                            <label class="mb-1">DNI Requerido</label>
                                            <input type="text" class="form-control" value={expedienteData.dniReq}
                                                onChange={(e) =>
                                                    setExpedienteData((prevState) => ({
                                                        ...prevState,
                                                        dniReq: e.target.value,
                                                    }))} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 pe-5">
                            <div className="col divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('dniA').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                                <label htmlFor="dniA" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>DNI</label>
                                <input type="file" name="" id="dniA" multiple style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            dni: e.target.files,
                                        }))
                                    }} />
                                <div className="col m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.dni[4]?.name}</p>
                                </div>
                            </div>
                            <div className="col divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('cedulaA').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                                <label htmlFor="cedulaA" name='frenteCedula' id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>Cedula</label>
                                <input type="file" id="cedulaA" multiple style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            cedula: e.target.files,
                                        }))
                                    }} />
                                <div className="col m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.cedula[4]?.name}</p>
                                </div>
                            </div>
                            <div className="col divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('registroA').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                                <label htmlFor="registroA" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>Registro</label>
                                <input type="file" name="" id="registroA" multiple style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            registro: e.target.files,
                                        }))
                                    }} />
                                <div className="col m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.registro[4]?.name}</p>
                                </div>
                            </div>
                            <div className="col divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('presupuesto').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                                <label htmlFor="presupuesto" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>Presupuesto</label>
                                <input type="file" name="" multiple id="presupuesto" style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            presupuesto: e.target.files,
                                        }))
                                    }} />
                                <div className="col m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.presupuesto[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.presupuesto[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.presupuesto[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.presupuesto[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.presupuesto[4]?.name}</p>
                                </div>
                            </div>
                            <div className="col divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('ocho').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-3"></i>
                                <label htmlFor="ocho" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>Certificado 08</label>
                                <input type="file" name="" id="ocho" multiple style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            certificado: e.target.files,
                                        }))
                                    }} />
                                <div className="col m-0">
                                    <p className="m-0 p-0" style={{ fontSize: '12px' }}>{doc.certificado[0]?.name}</p>
                                    <p className="m-0 p-0" style={{ fontSize: '12px' }}>{doc.certificado[1]?.name}</p>
                                    {doc.certificado.length === 3 ? <p className="m-0 p-0 center text-danger" style={{ fontSize: '11px' }}>{`${doc.certificado.length - 2} archivo más`}</p> : <p className="m-0 p-0"></p>}
                                    {doc.certificado.length > 3 ? <p className="m-0 p-0 center text-danger" style={{ fontSize: '11px' }}>{`${doc.certificado.length - 2} archivos más`}</p> : <p className="m-0 p-0"></p>}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 pe-5">
                            <div className="col divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('automotor').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-4"></i>
                                <label htmlFor="automotor" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>Automotor</label>
                                <input type="file" name="" multiple id="automotor" style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            automotor: e.target.files,
                                        }))
                                    }} />
                                <div className="col m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.automotor[4]?.name}</p>
                                </div>
                            </div>
                            <div className="col divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('fotos').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-4"></i>
                                <label htmlFor="fotos" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>Fotos</label>
                                <input type="file" name="" id="fotos" multiple style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            fotos: e.target.files,
                                        }))
                                    }} />
                                <div className="col m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.fotos[1]?.name}</p>
                                    {doc.fotos.length === 3 ? <p className="m-0 p-0 center text-danger" style={{ fontSize: '11px' }}>{`${doc.fotos.length - 2} archivo más`}</p> : <p className="m-0 p-0"></p>}
                                    {doc.fotos.length > 3 ? <p className="m-0 p-0 center text-danger" style={{ fontSize: '11px' }}>{`${doc.fotos.length - 2} archivos más`}</p> : <p className="m-0 p-0"></p>}
                                </div>
                            </div>
                            <div className="col divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('lesionesDoc').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-4"></i>
                                <label htmlFor="lesionesDoc" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>Lesiones Doc</label>
                                <input type="file" name="" multiple id="lesionesDoc" style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            lesionesDoc: e.target.files,
                                        }))
                                    }} />
                                <div className="col m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.lesionesDoc[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.lesionesDoc[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.lesionesDoc[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.lesionesDoc[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.lesionesDoc[4]?.name}</p>
                                </div>
                            </div>
                            <div className="col divAdjuntos mb-0" onClick={(e) => { e.stopPropagation(); document.getElementById('lesionesFotos').click() }}>
                                <i class="bi bi-cloud-upload iconUpload center letra mt-4"></i>
                                <label htmlFor="lesionesFotos" id="labelDoc" className="center mt-1" style={{ color: '#0722A8' }} onClick={(e) => e.preventDefault()}>Lesiones Fotos</label>
                                <input type="file" name="" multiple id="lesionesFotos" style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setDoc((prevState) => ({
                                            ...prevState,
                                            lesionesFotos: e.target.files,
                                        }))
                                    }} />
                                <div className="col m-0">
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.lesionesFotos[0]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.lesionesFotos[1]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.lesionesFotos[2]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.lesionesFotos[3]?.name}</p>
                                    <p className="m-0 p-0 center" style={{ fontSize: '12px' }}>{doc.lesionesFotos[4]?.name}</p>
                                </div>
                            </div>
                            <div className="col mb-0 divAdjuntosVacio"></div>
                        </div>
                        <div className="center">
                            <button type="submit" disabled={!regEx.test(expedienteData.dominio) || !regEx.test(expedienteData.dominioReq) ? true : (carga ? true : (hnrsPas && expedienteData.honorarios < 5 ? true : false))} className="btn btn-primary mt-5">Crear</button>
                            {carga ?
                                <div className="center">
                                    <div class="spinner-border text-primary center" role="status">
                                        <span class="visually-hidden center">Loading...</span>
                                    </div>
                                </div> : <div></div>}
                        </div>
                        <p className={expedienteData.dominio && !regEx.test(expedienteData.dominio) ? "text-danger center mt-1" : 'd-none'}>El formato de la patente en Dominio es incorrecto</p>
                        <p className={!regEx.test(expedienteData.dominioReq) ? "text-danger center mt-1" : 'd-none'}>El formato de la patente en Dominio Requerido es incorrecto</p>
                        <p>{dom()}</p>
                        <p>{dom1()}</p>
                    </form>
                </div> : <div></div>}
            </section> : <h4 className="p-5">Se necesita iniciar sesion</h4>}
        </main>
    );
}
