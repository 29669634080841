import SeccionExpedienteEditar from "./SeccionExpedienteEditar";
import SeccionExpedienteLeer from "./SeccionExpedienteLeer";
import { editarContext } from "../../../context/Editar";
import { useContext} from "react";
export default function SeccionExpediente({expediente}) {
    const {editar} = useContext(editarContext)
    return (
        <>
            {
                editar ?
                    <SeccionExpedienteEditar expediente={expediente} />
                    :
                    <SeccionExpedienteLeer expediente={expediente}/>
            }
        </>
    )
}