import './linea.css'
export default function LineaProgreso(expediente) {
    const img = {height: '60px', width: '100%', maxWidth: '1000px',}
    let clas = ''
    const estado = expediente.expediente.estado
    if(estado === 'RECEPCIONADO') {
        clas = 'progreso/recibido.png'
    }
    else if (estado === 'SIN DENUNCIA') {
        clas = 'progreso/denuncia.png'
    } 
    else if (estado === 'ENVIADO A COTIZAR') {
        clas = 'progreso/cotizar.png'
    } 
    else if (estado === 'PRES SIN RESOLUCION') {
        clas = 'progreso/pres.png'
    } 
    else if (estado === 'ACEPTACION') {
        clas = 'progreso/aceptacion.png'
    } 
    else if (estado === 'EN PROCESO DE PAGO') {
        clas = 'progreso/enProceso.png'
    }
    else if (estado === 'COBRADO') {
        clas = 'progreso/cobrado.png'
    }
    else if (estado === 'MEDIACION') {
        clas = 'progreso/mediacion.png'
    }
    else if (estado === 'CERRADO') {
        clas = 'progreso/rechazado.png'
    }
    return (
        <div className="lineaProgresoAnclado center">
            <img src={clas} alt="estado" style={img} className='center'/>
        </div>
    )
}