import { useMediaQuery } from 'react-responsive'
import './expedienteCliente.css'
import banner from '../images/bannerLoginCliente.jpeg'
import PlanillasCliente from '../components/ExpedienteCliente/PlanillasCliente';
import { useState } from 'react';
export default function ExpedienteCliente() {
    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });

    const logo375 = { height: '22px', width: '22px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const logo412 = { height: '22px', width: '22px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const logo480 = { height: '22px', width: '22px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    const logoPc = { height: '22px', width: '22px', background: 'transparent 0% 0% no-repeat padding-box', opacity: 1 }
    
    const expediente = JSON.parse(localStorage.getItem('expCliente'));
    return (
        <>
            <main className="mainLoginCliente">
                <section className="fotoMainClienteLadoIzqContenedor"><img src={banner} alt="" className='fotoMainClienteLadoIzq' /></section>
                <section className=''>
                    <div className='expNroCliente'>EXP NRO {expediente.nro}</div>
                    <div className="infoFechasClienteContenedor">
                        <div class="">
                            <p className="m-0 subtituloTituloExpCliente">F. Expediente</p>
                            <p className="textoSubtituloExp">{expediente.fechaExp}</p>
                        </div>
                        <div class="">
                            <p className="m-0 subtituloTituloExpCliente">Patente</p>
                            <p className="textoSubtituloExp">{expediente.dominioCliente}</p>
                        </div>
                        <div class="">
                            <p className="m-0 subtituloTituloExpCliente">F. Siniestro</p>
                            <p className="textoSubtituloExp">{expediente.fechaStro}</p>
                        </div>
                    </div>
                    <div className='estadoCliente'>ESTADO: <strong className='ms-2' style={{fontWeight: 'bold'}}>{expediente.estado}</strong></div>
                    <div className='textoExplicacionCliente'>El expediente ha sido recibido, nuestro personal está realizando las tareas de análisis de mecánica, a la vez que se realizan las diligencias ante la compañía del responsable para iniciar los tramites de presentación</div>
                    <PlanillasCliente expediente={expediente}/>
                </section>
            </main>
        </>
    )
}

