import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useContext } from "react";
import { editarContext } from "../../../context/Editar";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { useCookies } from "react-cookie";
import { vencimientoContext } from "../../../context/Vencimiento";
import "./expedienteEditar.css";
registerLocale("es", es);
export default function SeccionExpedienteEditar({ expediente }) {
    const [detalles, setDetalles] = useState([]);
    useEffect(() => {
        axios
            .get(
                `https://api.tuveunchoque.com.ar/api/gerencia/detalles/${expediente.id}`
            )
            .then((res) => {
                setDetalles(res.data);
            });
    }, [expediente]);
    const [asignados, setAsignados] = useState([]);
    useEffect(() => {
        axios
            .get("https://api.tuveunchoque.com.ar/api/gerencia/asignados")
            .then((res) => {
                setAsignados(res.data);
            });
    }, []);
    const div = { height: "35px", width: "100%", backgroundColor: "#e9ecef" };
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const a = expediente.fechaVencimiento?.split("/");
    const b = expediente.fechaExp?.split("/");
    const c = expediente.fechaStro?.split("/");
    const ultMensaje = detalles?.reverse()[0];
    const mensaje = detalles.length > 0 ? ultMensaje["OBSERVACIONES"] : null;
    const [expedienteData, setExpedienteData] = useState({
        idExpediente: expediente.id,
        exp2: expediente.fechaExp,
        fechaExp: new Date(b[2] + "/" + b[1] + "/" + b[0]).toLocaleDateString(
            "fr-FR"
        ),
        stro2: expediente.fechaStro,
        fechaStro: new Date(c[2] + "/" + c[1] + "/" + c[0]).toLocaleDateString(
            "fr-FR"
        ),
        estado: expediente.estado,
        monto: expediente.monto,
        asignado: expediente.asignadoA,
        nivel: expediente.nivel,
        capital: expediente.capital,
        vencimiento2: expediente.fechaVencimiento,
        vencimiento: new Date(a[2] + "/" + a[1] + "/" + a[0]).toLocaleDateString(
            "fr-FR"
        ),
        ticket: expediente.ticket,
        callbell: expediente.callbell,
        infoAdicional: expediente.infoAdicional,
        prioridad: expediente.prioridad,
        sharepoint: expediente.sharepoint,
    });
    const [carga, setCarga] = useState(false);
    const hoy = new Date().toISOString().split("T")[0];
    const { vencimiento, setVencimiento } = useContext(vencimientoContext);
    const estadoInput = [
        "RECEPCIONADO",
        "SIN DENUNCIA",
        "ENVIADO A COTIZAR",
        "PRES SIN RESOLUCION",
        "ACEPTACION",
        "EN PROCESO DE PAGO",
        "COBRADO",
        "MEDIACION",
        "CERRADO",
    ];
    const recepcionadoInput = asignados.filter((a) => a.recepcionado === 1).map((a) => a.nombre);
    const sinDenunciaInput = asignados.filter((a) => a.sinDenuncia === 1).map((a) => a.nombre);
    const envCotizarInput = asignados.filter((a) => a.envCotizar === 1).map((a) => a.nombre);
    const presSinResInput = asignados.filter((a) => a.presSinRes === 1).map((a) => a.nombre);
    const aceptacionInput = asignados.filter((a) => a.aceptacion === 1).map((a) => a.nombre);
    const procPagoInput = asignados.filter((a) => a.procPago === 1).map((a) => a.nombre);
    const cobradoInput = asignados.filter((a) => a.cobrado === 1).map((a) => a.nombre);
    const mediacionInput = asignados.filter((a) => a.mediacion === 1).map((a) => a.nombre);
    const cerradoInput = asignados.filter((a) => a.cerrado === 1).map((a) => a.nombre);
    const nivelInput = ["1", "2", "3"];
    const prioridadInput = ["ALTA", "MEDIA", "BAJA"];
    const divInfo = { height: "100px", width: "100%" };
    const { editar, setEditarTrue, setEditarFalse } = useContext(editarContext);
    function isOnlyNumbers(input) {
        if (input === '' || input === null) {
            return true;
        }
        const regex = /^[0-9]+$/;
        return regex.test(input);
    }
    const expedienteDataUpdate = useCallback(
        async (e) => {
            e.preventDefault();
            const response = await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update`,
                    {
                        id: expedienteData.idExpediente,
                        fechaExp: expedienteData.fechaExp,
                        fechaStro: expedienteData.fechaStro,
                        estado: expedienteData.estado,
                        monto: expedienteData.monto,
                        asignado: expedienteData.asignado,
                        nivel: expedienteData.nivel,
                        capital: expedienteData.capital,
                        honorariosCobro: expediente.honorariosCobro,
                        honorariosFacturado: expediente.montoInd,
                        facturado: expediente.facturadoPor,
                        estadoViejo: expediente.estado,
                        fechaCambioEstadoAct: hoy,
                        ticket: expedienteData.ticket,
                        callbell: expedienteData.callbell,
                        infoAdicional: expedienteData.infoAdicional,
                        vencimiento: new Date(vencimiento).toLocaleDateString("fr-FR"),
                        prioridad: expedienteData.monto > 1000000 ? "ALTA" : expedienteData.prioridad,
                        visibleViejo: expediente.visibleTicket,
                        asignadoViejo: expediente.asignadoA,
                        modificadoPor: cookies.user.nombre,
                        sharepoint: expedienteData.sharepoint,
                        esNovedades: cookies.user.nombre !== expedienteData.asignado ? 1 : 0,
                        iconoMediacion: expediente.iconoMediacion,
                        comp: expediente.compania,
                        // si el usuario cookies es distinto al usuario que esta asignando el expediente, y el usuario dele xpediente es distinto al usuario que se esta asignando entonces 1 sino 0
                        nuevoAsignado: cookies.user.nombre !== expedienteData.asignado && expediente.asignadoA !== expedienteData.asignado ? 1 : 0,
                        cargo: cookies.user.cargo,
                        asignadoPor: cookies.user.nombre,
                    }
                )
                .then(async (response) => {
                    setCarga(true);
                    if (response) {
                        if (expediente.asignadoA !== expedienteData.asignado) {
                                if (expedienteData.estado === "RECEPCIONADO") {
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/recepcionado`,
                                        {
                                            nro: expediente.nro,
                                            pas: expediente.productorAsociado,
                                            honorariosPas: expediente.honorarios,
                                            patente: expediente.dominioCliente,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } else if (expedienteData.estado === "EN PROCESO DE PAGO") {
                                    alert(
                                        "SE REQUIERE ACTUALIZAR LA PLANILLA COBRO YA QUE EL ESTADO PASO A EN PROCESO DE PAGO"
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/procesopago`,
                                        {
                                            nro: expediente.nro,
                                            pas: expediente.productorAsociado,
                                            capital: expediente.capital,
                                            plazoCob: expediente.plazoCobroCompania,
                                            patente: expediente.dominioCliente,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } else if (expedienteData.estado === "MEDIACION") {
                                    alert(
                                        "SE REQUIERE ACTUALIZAR HONORARIOS CLIENTE EN PLANTILLA COBRO YA QUE EL ESTADO PASO DE MEDIACION A EN PROCESO DE PAGO"
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mediacion/primerEtapa`,
                                        {
                                            id: expedienteData.idExpediente,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } else if (expedienteData.estado === "ENVIADO A COTIZAR") {
                                    alert(
                                        "SE REQUIERE ACTUALIZAR STRO CIA YA QUE EL ESTADO PASO A ENVIADO A COTIZAR"
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } else if (expedienteData.estado === "PRES SIN RESOLUCION" && (!expediente.nroReclamoCompaniaRequerido || expediente.polizaRequerido)) {
                                    alert(
                                        "SE REQUIERE ACTUALIZAR NRO SINIESTRO REQUERIDO YA QUE EL ESTADO PASO A PRES SIN RESOLUCION"
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } else if (expedienteData.estado === "PRES SIN RESOLUCION") {
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } else if (expedienteData.estado === "ACEPTACION" && expediente.productorAsociado === "CRA") {
                                    alert("FIRMAR CONVENIO DE HNS");
                                    alert(
                                        "SE REQUIERE ACTUALIZAR LA PLANILLA COBRO YA QUE EL ESTADO PASO A ACEPTACION"
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } else if (expedienteData.estado === "ACEPTACION") {
                                    alert(
                                        "SE REQUIERE ACTUALIZAR LA PLANILLA COBRO YA QUE EL ESTADO PASO A ACEPTACION"
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } else if (expedienteData.estado === "COBRADO") {
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cobrado`,
                                        {
                                            nro: expediente.nro,
                                            pas: expediente.productorAsociado,
                                            honorariosPas: expediente.honorarios,
                                            patente: expediente.dominioCliente,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } else if (expedienteData.estado === "CERRADO") {
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cerrado`,
                                        {
                                            nro: expediente.nro,
                                            pas: expediente.productorAsociado,
                                            patente: expediente.dominioCliente,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } else {
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cambioAsignado`,
                                        {
                                            nro: expediente.nro,
                                            mail: response.data,
                                            chat: expediente.ultimoMensaje,
                                            estado: expedienteData.estado,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/fechaAsignado`,
                                        {
                                            id: expedienteData.idExpediente,
                                            fecha: `${new Date().toLocaleDateString("fr-FR")}`,
                                        }
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/asignadoPor`,
                                        {
                                            id: expedienteData.idExpediente,
                                            asignadoPor: cookies.user.nombre,
                                        }
                                    );
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 3000);
                                } 
                        } else {
                            setTimeout(async () => {
                                if (expedienteData.estado === "RECEPCIONADO") {
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/recepcionado`,
                                        {
                                            nro: expediente.nro,
                                            pas: expediente.productorAsociado,
                                            honorariosPas: expediente.honorarios,
                                            patente: expediente.dominioCliente,
                                        }
                                    );
                                    window.location.reload();
                                } else if (expedienteData.estado === "EN PROCESO DE PAGO") {
                                    alert(
                                        "SE REQUIERE ACTUALIZAR LA PLANILLA COBRO YA QUE EL ESTADO PASO A EN PROCESO DE PAGO"
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/procesopago`,
                                        {
                                            nro: expediente.nro,
                                            pas: expediente.productorAsociado,
                                            capital: expediente.capital,
                                            plazoCob: expediente.plazoCobroCompania,
                                            patente: expediente.dominioCliente,
                                        }
                                    );
                                    window.location.reload();
                                } else if (expedienteData.estado === "MEDIACION") {
                                    alert(
                                        "SE REQUIERE ACTUALIZAR HONORARIOS CLIENTE EN PLANTILLA COBRO YA QUE EL ESTADO PASO DE MEDIACION A EN PROCESO DE PAGO"
                                    );
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mediacion/primerEtapa`,
                                        {
                                            id: expedienteData.idExpediente,
                                        }
                                    );
                                    window.location.reload();
                                } else if (expedienteData.estado === "ENVIADO A COTIZAR") {
                                    alert(
                                        "SE REQUIERE ACTUALIZAR STRO CIA YA QUE EL ESTADO PASO A ENVIADO A COTIZAR"
                                    );
                                    window.location.reload();
                                } else if (expedienteData.estado === "PRES SIN RESOLUCION" && (!expediente.nroReclamoCompaniaRequerido ||expediente.polizaRequerido)) {
                                    alert(
                                        "SE REQUIERE ACTUALIZAR NRO SINIESTRO REQUERIDO YA QUE EL ESTADO PASO A PRES SIN RESOLUCION"
                                    );
                                    window.location.reload();
                                } else if (expedienteData.estado === "ACEPTACION" && expediente.productorAsociado === "CRA") {
                                    alert("FIRMAR CONVENIO DE HNS");
                                    alert(
                                        "SE REQUIERE ACTUALIZAR LA PLANILLA COBRO YA QUE EL ESTADO PASO A ACEPTACION"
                                    );
                                    window.location.reload();
                                } else if (expedienteData.estado === "ACEPTACION") {
                                    alert(
                                        "SE REQUIERE ACTUALIZAR LA PLANILLA COBRO YA QUE EL ESTADO PASO A ACEPTACION"
                                    );
                                    window.location.reload();
                                } else if (expedienteData.estado === "COBRADO") {
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cobrado`,
                                        {
                                            nro: expediente.nro,
                                            pas: expediente.productorAsociado,
                                            honorariosPas: expediente.honorarios,
                                            patente: expediente.dominioCliente,
                                        }
                                    );
                                    window.location.reload();
                                } else if (expedienteData.estado === "CERRADO") {
                                    axios.post(
                                        `https://api.tuveunchoque.com.ar/api/mail/cerrado`,
                                        {
                                            nro: expediente.nro,
                                            pas: expediente.productorAsociado,
                                            patente: expediente.dominioCliente,
                                        }
                                    );
                                    window.location.reload();
                                } else {
                                    window.location.reload();
                                }
                            }, 1000);
                        }
                    }
                });
        },
        [expedienteData, vencimiento, expediente]
    );
    return (
        <div>
            <form className="mt-1 letra pe-3 ps-3" onSubmit={expedienteDataUpdate}>
                <div class="mb-2 row">
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">Estado</label>
                        <Select
                            className="w-100"
                            options={estadoInput.map((estado) => ({
                                value: estado,
                                label: estado,
                            }))}
                            placeholder={expediente.estado}
                            onChange={(newValue) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    estado: newValue.value,
                                }))
                            }
                        />
                    </div>
                    {expedienteData.estado === "RECEPCIONADO" ? (
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                            <Select
                                options={recepcionadoInput
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((asignado) => ({ value: asignado, label: asignado }))}
                                placeholder={expediente.asignadoA}
                                isDisabled={cookies.user.cargo === "AGENTE" ? true : false}
                                onChange={(newValue) =>
                                    setExpedienteData((prevState) => ({
                                        ...prevState,
                                        asignado: newValue.value,
                                    }))
                                }
                            />
                        </div>
                    ) : expedienteData.estado === "SIN DENUNCIA" ? (
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                            <Select
                                options={sinDenunciaInput
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((asignado) => ({ value: asignado, label: asignado }))}
                                placeholder={expediente.asignadoA}
                                isDisabled={cookies.user.cargo === "AGENTE" ? true : false}
                                onChange={(newValue) =>
                                    setExpedienteData((prevState) => ({
                                        ...prevState,
                                        asignado: newValue.value,
                                    }))
                                }
                            />
                        </div>
                    ) : expedienteData.estado === "ENVIADO A COTIZAR" ? (
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                            <Select
                                options={envCotizarInput
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((asignado) => ({ value: asignado, label: asignado }))}
                                placeholder={expediente.asignadoA}
                                isDisabled={cookies.user.cargo === "AGENTE" ? true : false}
                                onChange={(newValue) =>
                                    setExpedienteData((prevState) => ({
                                        ...prevState,
                                        asignado: newValue.value,
                                    }))
                                }
                            />
                        </div>
                    ) : expedienteData.estado === "PRES SIN RESOLUCION" ? (
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                            <Select
                                options={presSinResInput
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((asignado) => ({ value: asignado, label: asignado }))}
                                placeholder={expediente.asignadoA}
                                isDisabled={cookies.user.cargo === "AGENTE" ? true : false}
                                onChange={(newValue) =>
                                    setExpedienteData((prevState) => ({
                                        ...prevState,
                                        asignado: newValue.value,
                                    }))
                                }
                            />
                        </div>
                    ) : expedienteData.estado === "ACEPTACION" ? (
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                            <Select
                                options={aceptacionInput
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((asignado) => ({ value: asignado, label: asignado }))}
                                placeholder={expediente.asignadoA}
                                isDisabled={cookies.user.cargo === "AGENTE" ? true : false}
                                onChange={(newValue) =>
                                    setExpedienteData((prevState) => ({
                                        ...prevState,
                                        asignado: newValue.value,
                                    }))
                                }
                            />
                        </div>
                    ) : expedienteData.estado === "EN PROCESO DE PAGO" ? (
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                            <Select
                                options={procPagoInput
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((asignado) => ({ value: asignado, label: asignado }))}
                                placeholder={expediente.asignadoA}
                                isDisabled={cookies.user.cargo === "AGENTE" ? true : false}
                                onChange={(newValue) =>
                                    setExpedienteData((prevState) => ({
                                        ...prevState,
                                        asignado: newValue.value,
                                    }))
                                }
                            />
                        </div>
                    ) : expedienteData.estado === "COBRADO" ? (
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                            <Select
                                options={cobradoInput
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((asignado) => ({ value: asignado, label: asignado }))}
                                placeholder={expediente.asignadoA}
                                isDisabled={cookies.user.cargo === "AGENTE" ? true : false}
                                onChange={(newValue) =>
                                    setExpedienteData((prevState) => ({
                                        ...prevState,
                                        asignado: newValue.value,
                                    }))
                                }
                            />
                        </div>
                    ) : expedienteData.estado === "MEDIACION" ? (
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                            <Select
                                options={mediacionInput
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((asignado) => ({ value: asignado, label: asignado }))}
                                placeholder={expediente.asignadoA}
                                isDisabled={cookies.user.cargo === "AGENTE" ? true : false}
                                onChange={(newValue) =>
                                    setExpedienteData((prevState) => ({
                                        ...prevState,
                                        asignado: newValue.value,
                                    }))
                                }
                            />
                        </div>
                    ) : expedienteData.estado === "CERRADO" ? (
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                            <Select
                                options={cerradoInput
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((asignado) => ({ value: asignado, label: asignado }))}
                                placeholder={expediente.asignadoA}
                                isDisabled={cookies.user.cargo === "AGENTE" ? true : false}
                                onChange={(newValue) =>
                                    setExpedienteData((prevState) => ({
                                        ...prevState,
                                        asignado: newValue.value,
                                    }))
                                }
                            />
                        </div>
                    ) : (
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Asignado a</label>
                            <Select
                                options={estadoInput
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((asignado) => ({ value: asignado, label: asignado }))}
                                placeholder={expediente.asignadoA}
                                onChange={(newValue) =>
                                    setExpedienteData((prevState) => ({
                                        ...prevState,
                                        asignado: newValue.value,
                                    }))
                                }
                            />
                        </div>
                    )}
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">Capital</label>
                        <input
                            type="number"
                            class="form-control"
                            value={expedienteData.capital}
                            placeholder={expediente.capital}
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    capital: e.target.value,
                                }))
                            }
                        />
                    </div>
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Prioridad</label>
                        <Select
                            options={prioridadInput.map((p) => ({ value: p, label: p }))}
                            placeholder={expediente.prioridad}
                            required={expedienteData.estado === "RECEPCIONADO" ? true : false}
                            onChange={(newValue) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    prioridad: newValue.value,
                                }))
                            }
                        />
                    </div>
                </div>
                <div class="mb-2 row">
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">Monto Reclamado</label>
                        <input
                            type="text"
                            class="form-control"
                            value={expedienteData.monto}
                            placeholder={expediente.monto}
                            required={
                                expedienteData.estado === "PRES SIN RESOLUCION" ? true : false
                            }
                            onChange={(e) => {
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    monto: e.target.value,
                                }));
                            }}
                        />
                    </div>
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">SharePoint</label>
                        <input
                            type="text"
                            class="form-control"
                            value={expedienteData.sharepoint}
                            placeholder={expediente.sharepoint}
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    sharepoint: e.target.value,
                                }))
                            }
                        />
                    </div>
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">Ticket</label>
                        <input
                            type="text"
                            class="form-control"
                            value={expedienteData.ticket}
                            placeholder={expediente.ticket}
                            required={expedienteData.estado === "RECEPCIONADO" ? true : false}
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    ticket: e.target.value,
                                }))
                            }
                        />
                    </div>
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Callbell</label>
                        <input
                            type="text"
                            class="form-control"
                            value={expedienteData.callbell}
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    callbell: e.target.value,
                                }))
                            }
                        />
                    </div>
                </div>
                <div class="mb-2 row">
                    <div className="col">
                        <label class="mb-1 letraPlanillaExpediente">Informacion Adicional</label>
                        <textarea
                            className="form-control"
                            style={divInfo}
                            value={expedienteData.infoAdicional}
                            onChange={(e) =>
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    infoAdicional: e.target.value,
                                }))
                            }
                        />
                    </div>
                </div>
                <div class="mb-2 row">
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Fecha Stro</label>
                        <DatePicker
                            locale="es"
                            value={expedienteData.fechaStro}
                            className="form-control"
                            dateFormat="d MMM yyyy"
                            onChange={(e) => {
                                const d = new Date(e).toLocaleDateString("fr-FR");
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    fechaStro: d,
                                }));
                            }}
                        />
                    </div>
                    <div className="col">
                        <label className="mb-1 letraPlanillaExpediente">
                            Fecha Exp
                        </label>
                        <DatePicker
                            locale="es"
                            value={expedienteData.fechaExp}
                            className="form-control"
                            dateFormat="d MMM yyyy"
                            onChange={(e) => {
                                const d = new Date(e).toLocaleDateString("fr-FR");
                                setExpedienteData((prevState) => ({
                                    ...prevState,
                                    fechaExp: d,
                                }));
                            }}
                        />
                    </div>
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Fecha Cambio de Estado</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder={expediente.fechaCambioEstado}
                            disabled
                        />
                    </div>
                    <div class="col">
                        <label class="mb-1 letraPlanillaExpediente">Ultima Modificación</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder={expediente.ultimaMod?.split("-").reverse().join("-")}
                            disabled
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-4 mb-3">
                    <button
                        type="submit"
                        className={
                            !vencimiento && expedienteData.estado !== "CERRADO"
                                ? "d-none"
                                : "btn-primary btn"
                        }
                        disabled={
                            expedienteData.estado === "PRES SIN RESOLUCION" && expediente.vistoRegistroSiniestralidad === 0 ? true
                                : isOnlyNumbers(expedienteData.monto) === false ? true
                                    : isOnlyNumbers(expedienteData.capital) === false ? true
                                        : expedienteData.estado === "RECEPCIONADO" && !recepcionadoInput.includes(expedienteData.asignado) ? true
                                            : expedienteData.estado === "SIN DENUNCIA" && !sinDenunciaInput.includes(expedienteData.asignado) ? true
                                                : expedienteData.estado === "ENVIADO A COTIZAR" && !envCotizarInput.includes(expedienteData.asignado) ? true
                                                    : expedienteData.estado === "PRES SIN RESOLUCION" && !presSinResInput.includes(expedienteData.asignado) ? true
                                                        : expedienteData.estado === "ACEPTACION" && !aceptacionInput.includes(expedienteData.asignado) ? true
                                                            : expedienteData.estado === "EN PROCESO DE PAGO" && !procPagoInput.includes(expedienteData.asignado) ? true
                                                                : expedienteData.estado === "COBRADO" && !cobradoInput.includes(expedienteData.asignado) ? true
                                                                    : expedienteData.estado === "MEDIACION" && !mediacionInput.includes(expedienteData.asignado) ? true
                                                                        : expedienteData.estado === "CERRADO" && !cerradoInput.includes(expedienteData.asignado) ? true
                                                                            : expedienteData.estado === "EN PROCESO DE PAGO" &&
                                                                                (expediente.capital === "" ||
                                                                                    expediente.mesEstimado === null ||
                                                                                    expediente.honorariosFacturado === "" ||
                                                                                    expediente.nroFactura === null ||
                                                                                    expediente.facturadoPor === null)
                                                                                ? true
                                                                                : false
                        }
                    >
                        Actualizar
                    </button>
                    <button
                        className="btn btn-secondary btn-sm ms-5"
                        onClick={setEditarFalse}
                    >
                        Cancelar
                    </button>
                </div>
                {!vencimiento && expedienteData.estado !== "CERRADO" ? (
                    <div className="center">
                        <p className="text-danger">
                            Se necesita actualizar la fecha de vencimiento
                        </p>
                    </div>
                ) : (
                    <div></div>
                )}
                <div
                    className={
                        expedienteData.estado === "RECEPCIONADO" &&
                            !recepcionadoInput.includes(expedienteData.asignado)
                            ? "center"
                            : expedienteData.estado === "SIN DENUNCIA" &&
                                !sinDenunciaInput.includes(expedienteData.asignado)
                                ? "center"
                                : expedienteData.estado === "ENVIADO A COTIZAR" &&
                                    !envCotizarInput.includes(expedienteData.asignado)
                                    ? "center"
                                    : expedienteData.estado === "PRES SIN RESOLUCION" &&
                                        !presSinResInput.includes(expedienteData.asignado)
                                        ? "center"
                                        : expedienteData.estado === "ACEPTACION" &&
                                            !aceptacionInput.includes(expedienteData.asignado)
                                            ? "center"
                                            : expedienteData.estado === "EN PROCESO DE PAGO" &&
                                                !procPagoInput.includes(expedienteData.asignado)
                                                ? "center"
                                                : expedienteData.estado === "COBRADO" &&
                                                    !cobradoInput.includes(expedienteData.asignado)
                                                    ? "center"
                                                    : expedienteData.estado === "MEDIACION" &&
                                                        !mediacionInput.includes(expedienteData.asignado)
                                                        ? "center"
                                                        : expedienteData.estado === "CERRADO" &&
                                                            !cerradoInput.includes(expedienteData.asignado)
                                                            ? "center"
                                                            : "d-none"
                    }
                >
                    <p className="text-danger">
                        Usuario asignado no autorizado para estado seleccionado
                    </p>
                </div>
                <div
                    className={
                        expedienteData.estado === "EN PROCESO DE PAGO" &&
                            (expediente.capital === "" ||
                                expediente.mesEstimado === null ||
                                expediente.honorariosFacturado === "" ||
                                expediente.nroFactura === null ||
                                expediente.facturadoPor === null)
                            ? "center"
                            : "d-none"
                    }
                >
                    <p className="text-danger">
                        Se necesita actualizar los valores de cobro para pasar a EN PROCESO
                        DE PAGO
                    </p>
                </div>
                {isOnlyNumbers(expedienteData.monto) === false ? (
                    <div className="center">
                        <p className="text-danger">
                            El campo Monto Reclamado contiene valores diferentes a nros
                        </p>
                    </div>
                ) : (
                    <div></div>
                )}
                {isOnlyNumbers(expedienteData.capital) === false ? (
                    <div className="center">
                        <p className="text-danger">
                            El campo Capital contiene valores diferentes a nros
                        </p>
                    </div>
                ) : (
                    <div></div>
                )}
                {expedienteData.estado === 'PRES SIN RESOLUCION' && expediente.vistoRegistroSiniestralidad === 0 ? (
                    <div className="center">
                        <p className="text-danger">
                            Se necesita marcar como leído el registro de siniestralidad
                        </p>
                    </div>
                ) : (
                    <div></div>
                )}
                {carga ? (
                    <div className="center">
                        <div class="spinner-border text-primary center" role="status">
                            <span class="visually-hidden center">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </form>
        </div>
    );
}
